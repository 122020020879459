/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import {State} from 'react-router';
import TrafficStore from '../stores/TrafficStore';
import MapPageStore from '../stores/MapPageStore';
import SessionStore from '../stores/SessionStore';
import actionCreators from '../actions/actionCreators';
import LegendPanelComponent from './Map/LegendPanel.jsx';
import GraphComponent from '../components/graph/Graph.jsx';
import GraphSpinner from '../components/graph/GraphSpinner.jsx';
import SearchBar from './Map/SearchBar.jsx';
import CommandPanelComponent from '../components/CommandPanel/CommandPanel.jsx';
import AppMapTypePanel from './Map/AppMapTypePanel.jsx';
import PolicyVersionPanelComponent from './Map/PolicyVersionPanel.jsx';
import TrafficFilterComponent from './Map/TrafficFilter.jsx';
import {MapNotification, MapNotificationContainer} from './Map/MapNotification.jsx';
import RouterMixin from '../mixins/RouterMixin';
import Tooltip from './Map/Tooltip.jsx';
import RightClickMenu from './Map/RightClickMenu.jsx';
import {LabelStore} from '../stores';
import UserStore from '../stores/UserStore';

export default Object.assign(
  React.createClass({
    mixins: [State, RouterMixin],

    getInitialState() {
      return {
        mapLevel: MapPageStore.getMapLevel(),
        mapRoute: MapPageStore.getMapRoute(),
        mapRouteName: MapPageStore.getMapRouteName(),
        mapPolicyVersion: MapPageStore.getPolicyVersion(),
        appMapVersion: MapPageStore.getAppMapVersion(),
        mapType: MapPageStore.getMapType(),
        showMT4LWarning: false,
      };
    },

    componentWillMount() {
      if (!SessionStore.isIlluminationApiEnabled()) {
        this.replaceWith('landing');
      }

      if (!SessionStore.isTrafficEnabled()) {
        this.replaceWith('appGroups');
      }
    },

    componentDidMount() {
      this.renderGraph = true;
      MapPageStore.addChangeListener(this.onChange);
      actionCreators.updateGraphCalculated();
      actionCreators.updateMapType('app');
      actionCreators.updateMapRoute(this.getParams());

      if (MapPageStore.getMapLevel() === 'globalAppGroup') {
        sessionStorage.setItem('app_group_list', 'recents');
      } else {
        sessionStorage.setItem('recent_app_map_type', 'appMap');
      }

      if (SessionStore.isSuperclusterMember()) {
        actionCreators.setMapNotification('open');
      }

      const labels = LabelStore.getLabelSettingsList();

      if (LabelStore.getLabelSettingsList()) {
        const hideMT4LWarningMap = localStorage.getItem('hideMT4LWarning-appGroupMap');

        // Don't show the warning on the app group map landing page because it is used for both types of map
        if (hideMT4LWarningMap || _.isEmpty(this.props.params)) {
          this.setState({showMT4LWarning: false});
        } else {
          this.setState({showMT4LWarning: labels.length > 4});
        }
      }
    },

    componentWillReceiveProps() {
      if (!_.isEqual(this.getParams(), this.state.mapRoute)) {
        _.defer(() => {
          actionCreators.unselectComponent();
          actionCreators.updateGraphCalculated();
          actionCreators.updateZoomToFit();
          actionCreators.updateMapRoute(this.getParams());
        });
      }
    },

    componentWillUpdate(nextProps, nextState) {
      // Do not re-render the graph on a route change (wait for graph to be calculated)
      if (!_.isEqual(this.state.mapRoute, nextState.mapRoute)) {
        this.renderGraph = false;
      }
    },

    componentDidUpdate() {
      // Clear the route changed flag
      this.renderGraph = true;

      if (TrafficStore.isAppGroupsLoaded() && !TrafficStore.isAppGroupMapEnabled()) {
        this.replaceWith('illumination');
      }
    },

    componentWillUnmount() {
      actionCreators.forceListPageRefresh('rulesetList', true);
      MapPageStore.removeChangeListener(this.onChange);
    },

    onChange() {
      this.setState({
        mapLevel: MapPageStore.getMapLevel(),
        mapRoute: MapPageStore.getMapRoute(),
        mapRouteName: MapPageStore.getMapRouteName(),
        mapPolicyVersion: MapPageStore.getPolicyVersion(),
        appMapVersion: MapPageStore.getAppMapVersion(),
        mapNotification: MapPageStore.getMapNotification(),
        mapType: MapPageStore.getMapType(),
      });
    },

    delayMapLevel(nextMapRoute) {
      let routeName;

      actionCreators.updateGraphCalculated();

      if (_.isEmpty(nextMapRoute)) {
        routeName = 'appMap';
      } else if (nextMapRoute.type === 'focused') {
        routeName = 'appMapLevel';
      } else {
        routeName = 'prevAppMapLevel';
      }

      actionCreators.updateMapRoute(nextMapRoute);
      this.transitionTo(routeName, nextMapRoute);
      actionCreators.updateZoomToFit();
    },

    delayNavBack() {
      actionCreators.unselectComponent();
      actionCreators.updateGraphCalculated();

      let prevLevel = {};

      if (!_.isEmpty(this.state.mapRoute.prevtype)) {
        prevLevel = {
          type: this.state.mapRoute.prevtype,
          id: this.state.mapRoute.previd,
        };
        actionCreators.updateMapRoute(prevLevel);
        this.transitionTo('appMapLevel', prevLevel);
      } else if (sessionStorage.getItem('app_group_list') === 'list') {
        this.transitionTo('appGroups');
      } else {
        actionCreators.updateMapRoute(prevLevel);
        this.transitionTo('appMap', prevLevel);
      }

      actionCreators.updateZoomToFit();
    },

    updateMapLevel(nextMapLevel) {
      actionCreators.startSpinner();
      _.defer(() => {
        // Allow the spinner to start before transitioning the route.
        this.delayMapLevel(nextMapLevel);
      });
    },

    handleCloseNotification() {
      actionCreators.setMapNotification('close');
    },

    handleCloseMT4LNotification(event) {
      event.stopPropagation();
      this.setState({showMT4LWarning: false});
      localStorage.setItem('hideMT4LWarning-appGroupMap', 'true');
    },

    handleBack() {
      actionCreators.startSpinner();
      _.defer(() => {
        // Allow the spinner to start before transitioning the route.
        this.delayNavBack();
      });
    },

    render() {
      const {mapPolicyVersion, mapLevel, mapNotification, appMapVersion, mapType, showMT4LWarning} = this.state;
      const classes = cx(
        'MapPage',
        {AppMap: mapLevel !== 'globalAppGroup'},
        {'Graph-draft': mapPolicyVersion === 'draft'},
      );

      const disableButton = mapLevel ? mapLevel === 'globalAppGroup' : true;
      const disablePolicyVersionButton = !mapLevel;
      const isVulnerabilityEnabled = SessionStore.areVulnerabilitiesEnabled();

      const appMapType = sessionStorage.getItem('recent_app_map_type');
      const defaultAppMap = UserStore.getDefaultAppMap();
      const illuminationPlus =
        (!appMapType && defaultAppMap === 'illuminationPlus') || appMapType === 'illuminationPlus';

      const type = _.isEmpty(this.getParams()) ? 'main' : 'detail';

      return (
        <div className={classes} data-tid="app-group-map">
          <img className="Map-logo" width="72" src="images/illumio_logo.svg" alt="Illumio" />
          <SearchBar
            type="app"
            main={_.isEmpty(this.props.params)}
            policyVersion={mapPolicyVersion}
            appMapVersion={appMapVersion}
            routeType={type}
            route={illuminationPlus ? 'appGroupIllumination' : 'appMapLevel'}
          />
          <GraphComponent
            mapType="app"
            updateMapLevel={this.updateMapLevel}
            policyVersion={mapPolicyVersion}
            render={this.renderGraph}
          />
          <CommandPanelComponent updateMapLevel={this.updateMapLevel} />
          <TrafficFilterComponent
            mapType={mapType}
            mapLevel={mapLevel}
            policyVersion={mapPolicyVersion}
            appMapVersion={appMapVersion}
            disabled={disableButton}
          />
          <LegendPanelComponent
            mapLevel={mapLevel}
            policyVersion={mapPolicyVersion}
            mapType={mapType}
            disabled={disableButton}
          />
          <PolicyVersionPanelComponent
            mapType={mapType}
            appMapVersion={appMapVersion}
            policyVersion={mapPolicyVersion}
            disabled={disablePolicyVersionButton}
          />
          {isVulnerabilityEnabled && (
            <AppMapTypePanel mapType={mapType} appMapVersion={appMapVersion} policyVersion={mapPolicyVersion} />
          )}
          <GraphSpinner
            mapType={mapType}
            appMapVersion={appMapVersion}
            policyVersion={mapPolicyVersion}
            isVulnerabilityEnabled={isVulnerabilityEnabled}
          />
          <MapNotificationContainer>
            {mapNotification === 'open' && (
              <MapNotification
                type="warning"
                message={intl('Common.SuperClusterPartialDataWarning')}
                onClose={this.handleCloseNotification}
              />
            )}
            {showMT4LWarning && (
              <MapNotification
                type="warning"
                message={intl('IlluminationMap.MapWarning')}
                onClose={this.handleCloseMT4LNotification}
              />
            )}
          </MapNotificationContainer>
          <Tooltip />
          <RightClickMenu policyVersion={mapPolicyVersion} mapType="app" />
        </div>
      );
    },
  }),
  {
    viewName: () => intl('Common.AppGroupMap'),
    forRoute: 'app.appMap',
    isAvailable: () => SessionStore.isTrafficEnabled(),
  },
);
