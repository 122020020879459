/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import {GridDataUtils} from '../../utils';
import actionCreators from '../../actions/actionCreators';
import {RingFenceAppGroupGrid} from '../../components/PolicyGenerator';
import {RouterMixin, PolicyGeneratorMixin, StoreMixin} from '../../mixins';
import {TrafficStore, RulesetStore, MapSpinnerStore} from '../../stores';
import {PolicyGeneratorDisabledRulesetsDialog} from '../../modals';
import {ProgressBar, Button, ConfirmationDialog, Banner} from '../../components';

function getStateFromStores(receivedRulesets, currentValues) {
  const state = {...(currentValues || this.getPolicyGeneratorValues())};
  const rulesets = receivedRulesets || (this.state && this.state.rulesets) || {};

  state.appGroups = TrafficStore.getTrafficAppGroupNodes();

  state.appGroups = state.appGroups
    .filter(appGroup => appGroup.caps.rulesets.includes('read'))
    .map(appGroup => {
      if (rulesets[appGroup.name]) {
        // Add Ruleset information
        return {...appGroup, ...rulesets[appGroup.name]};
      }

      return appGroup;
    });

  state.updatingRules = MapSpinnerStore.getRuleCoverageSpinner() && TrafficStore.isPolicyGeneratorCoverageLoaded();

  return state;
}

export default React.createClass({
  mixins: [
    RouterMixin,
    PolicyGeneratorMixin,
    StoreMixin([TrafficStore, RulesetStore, MapSpinnerStore], getStateFromStores),
  ],

  getInitialState() {
    return {sorting: [{key: 'connections', direction: true}]};
  },

  async componentDidMount() {
    this.setAppGroup({ringfence: true});
    this.getData();

    const rulesets = this.getAllPolicyGeneratorRulesets();

    this.setState({...getStateFromStores(rulesets, this.getPolicyGeneratorValues), rulesets});
  },

  handleRowClick(row) {
    this.handleRowSelect(row.href);
  },

  handleRowSelect(selection) {
    const selected = GridDataUtils.selectToggle(this.state.selectedRingFenceAppGroups, selection);

    this.setSelectedRingFenceAppGroups(selected);
    this.setState({selectedRingFenceAppGroups: selected});
  },

  handleSort(key, direction) {
    this.setState({sorting: [{key, direction}]});
  },

  handleRefresh(evt, appGroup) {
    evt.stopPropagation();
    actionCreators.openDialog(
      <ConfirmationDialog
        title={intl('AppGroupCoverage.Recalculate')}
        message={intl('AppGroupCoverage.RecalculateConfirm')}
        onConfirm={_.partial(this.handleForceRefresh, appGroup)}
      />,
    );
  },

  handleForceRefresh(appGroup) {
    this.updateAppGroupRules(appGroup);
  },

  handleNext() {
    const disabled = this.state.appGroups.filter(
      appGroup => appGroup.disabled && this.state.selectedRingFenceAppGroups.includes(appGroup.href),
    );
    const deleted = this.state.appGroups.filter(
      appGroup => appGroup.deleted && this.state.selectedRingFenceAppGroups.includes(appGroup.href),
    );

    if (disabled.length || deleted.length) {
      actionCreators.openDialog(<PolicyGeneratorDisabledRulesetsDialog disabled={disabled} deleted={deleted} />);
    } else {
      this.transitionTo('ringFencePreview');
    }
  },

  handleHover(href) {
    this.setState({hover: href});
  },

  handleUnhover() {
    this.setState({hover: null});
  },

  handleFilteredSelect(selectionType) {
    let selected = [];
    const appGroups = this.state.appGroups.filter(appGroup => appGroup.caps.rulesets.includes('write'));

    switch (selectionType) {
      case 'all':
        selected = appGroups.map(appGroup => appGroup.href);
        break;
      case 'none':
        selected = [];
        break;
      case 'connections':
        selected = appGroups
          .filter(appGroup => appGroup.intraGroupCoverage && appGroup.intraGroupCoverage.num_services)
          .map(appGroup => appGroup.href);
        break;
      case 'noConnections':
        selected = appGroups
          .filter(appGroup => !appGroup.intraGroupCoverage || !appGroup.intraGroupCoverage.num_services)
          .map(appGroup => appGroup.href);
        break;
      case 'coverage':
        selected = appGroups
          .filter(
            appGroup =>
              appGroup.intraGroupCoverage &&
              appGroup.intraGroupCoverage.num_services - appGroup.intraGroupCoverage.num_rules &&
              (!appGroup.updated || appGroup.lastCalculated > appGroup.updated),
          )
          .map(appGroup => appGroup.href);
        break;
      default:
        break;
    }

    this.setSelectedRingFenceAppGroups(selected);
    this.setState({selectedRingFenceAppGroups: selected});
  },

  render() {
    const {appGroups, selectedRingFenceAppGroups, trafficSpinner, ruleSpinner} = this.state;
    const query = this.getQuery();

    if (trafficSpinner || ruleSpinner) {
      return (
        <div className="RBConfigure RBConfigure--Extra">
          <Banner
            type="progresscentered"
            header={intl('PolicyGenerator.CalculationInProgress')}
            message={intl('PolicyGenerator.Spinner.CalculatingRuleCoverage')}
          />
        </div>
      );
    }

    const filteredSelectOptions = [
      {key: 'all', value: intl('Common.All')},
      {key: 'none', value: intl('Common.None')},
      {key: 'connections', value: intl('PolicyGenerator.WithConnections')},
      {key: 'noConnections', value: intl('PolicyGenerator.WithoutConnections')},
      {key: 'coverage', value: intl('PolicyGenerator.IncompleteCoverage')},
    ];

    let ringFenceAppGroupTable = null;

    if (appGroups) {
      ringFenceAppGroupTable = (
        <RingFenceAppGroupGrid
          appGroups={appGroups}
          subtitle={intl('PolicyGenerator.RingFence.NumAppGroups', {count: appGroups.length})}
          selection={this.state.selectedRingFenceAppGroups}
          onRowSelect={this.handleRowSelect}
          onRowClick={this.handleRowClick}
          sorting={this.state.sorting}
          onSort={this.handleSort}
          onRefresh={this.handleRefresh}
          hover={this.state.hover}
          onHover={this.handleHover}
          onUnhover={this.handleUnhover}
          onFilteredSelect={this.handleFilteredSelect}
          filteredSelect={filteredSelectOptions}
        />
      );
    }

    return (
      <div className="RBConfigure RBConfigure--Extra--Choose RBConfigure--Extra">
        <div className="RBConfigure-Content">
          <ProgressBar
            steps={[intl('PolicyGenerator.RingFence.ChooseAppGroups'), intl('PolicyGenerator.PreviewRules')]}
            active={0}
          />
          <div className="RBConfigure-Content-Nav">
            <Button
              text={intl('Common.Back')}
              type="secondary"
              onClick={() =>
                query?.appgroups === 'true' ? this.transitionTo('appGroups') : this.transitionTo('policygenerator')
              }
              tid="back"
            />
            <Button
              text={intl('Common.Next')}
              disabled={!selectedRingFenceAppGroups.length}
              onClick={this.handleNext}
              tid="next"
            />
          </div>
          {ringFenceAppGroupTable}
        </div>
      </div>
    );
  },
});
