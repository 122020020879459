/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from '@illumio-shared/utils/intl';
import _ from 'lodash';
import actionCreators from '../../actions/actionCreators';
import {TrafficStore, RulesetStore, ServiceStore, OrgStore} from '../../stores';
import {ProgressBar, Button, Grid, ConfirmationDialog, Banner} from '../../components';
import {RouterMixin, PolicyGeneratorMixin, StoreMixin, UserMixin} from '../../mixins';
import {
  RestApiUtils,
  PolicyGeneratorUtils,
  RulesetUtils,
  GridDataUtils,
  RenderUtils,
  ProviderConsumerUtils,
} from '../../utils';

function getStateFromStores() {
  let appGroups = TrafficStore.getAllAppGroupNodes();

  if (!ServiceStore.isLoaded() || !appGroups.length) {
    return {};
  }

  const values = this.getPolicyGeneratorValues();

  appGroups = appGroups.filter(
    appGroup => values.selectedRingFenceAppGroups.includes(appGroup.href) && appGroup.caps.rulesets.includes('write'),
  );

  return {
    appGroups,
    ringFenceScopes: PolicyGeneratorUtils.getScopes(appGroups),
    ringFenceRule: PolicyGeneratorUtils.getRingFencingRule(),
    rulesets: RulesetStore.getAll('draft', 'rule_set_scopes'),
    providerConsumerOrder: OrgStore.providerConsumerOrder(),
  };
}

export default React.createClass({
  mixins: [
    RouterMixin,
    PolicyGeneratorMixin,
    UserMixin,
    StoreMixin([TrafficStore, RulesetStore, ServiceStore, OrgStore], getStateFromStores),
  ],

  componentDidMount() {
    this.getData();
  },

  getName(scope) {
    return TrafficStore.getAppGroupsType()
      .map(type => scope[type].value)
      .join(' | ');
  },

  handleCancel() {
    this.transitionTo('policygenerator');
  },

  handleSave() {
    const scopeCount = this.state.ringFenceScopes ? this.state.ringFenceScopes.length : 0;

    actionCreators.openDialog(
      <ConfirmationDialog
        title={intl('PolicyGenerator.SaveRules')}
        message={intl('PolicyGenerator.RulesetsWillBeSaved', {count: scopeCount})}
        onConfirm={this.handleForceSave}
      />,
    );
  },

  async handleForceSave() {
    const scopes = this.state.ringFenceScopes;
    let rulesetId = null;

    if (scopes.length) {
      this.setState({saveSpinner: true});

      for (const scope of scopes) {
        const name = this.getName(scope);
        const result = await Promise.all([
          RestApiUtils.ruleSets.getCollection(
            {
              representation: 'rule_set_services_labels_and_names',
              external_data_reference: TrafficStore.getAppGroupsType()
                .map(type => scope[type].href.split('/').pop())
                .sort((a, b) => a - b)
                .join(' | '),
            },
            'draft',
            true,
          ),
          RestApiUtils.ruleSets.getCollection(
            {
              representation: 'rule_set_services_labels_and_names',
              name,
            },
            'draft',
            true,
          ),
        ]);
        const resultRef = result[0].body;
        const resultName = result[1].body;

        if ((resultRef && resultRef.length) || (resultName && resultName.length)) {
          // Update the existing ruleset
          const ruleset = this.updateRingFenceRuleset(
            resultRef && resultRef.length ? resultRef[0] : resultName[0],
            scope,
          );

          rulesetId = GridDataUtils.getIdFromHref(ruleset.href);
          await RestApiUtils.ruleSet.update(rulesetId, PolicyGeneratorUtils.getStrippedRuleset(ruleset));
        } else {
          // Create a new ruleset
          await RestApiUtils.ruleSets.create(this.createRingFenceRuleset(scope));
        }
      }

      this.setState({saveSpinner: false});
      this.setRingFenceSuccessInfo({
        newScopes: scopes.length,
        rulesetId,
      });
      this.transitionTo('ringFenceSuccess');
    }

    this.setRingFenceSuccessInfo({
      newScopes: scopes.length,
      rulesetId,
    });
  },

  updateRingFenceRuleset(ruleset, scope) {
    const ringFenceRule = PolicyGeneratorUtils.getStrippedRules(this.state.ringFenceRule);

    // If a ringfence rule does not already exist
    if (
      !ruleset.rules.some(
        rule =>
          rule.update_type !== 'delete' &&
          rule.enabled &&
          rule.ingress_services[0] &&
          rule.ingress_services[0].href === ringFenceRule[0].ingress_services[0].href &&
          _.isEqual(rule.providers, ringFenceRule[0].providers) &&
          _.isEqual(rule.consumers, ringFenceRule[0].consumers),
      )
    ) {
      ruleset.rules = ruleset.rules.concat(PolicyGeneratorUtils.getStrippedRules(this.state.ringFenceRule));
    }

    ruleset.external_data_set = 'illumio_policy_generator';
    ruleset.external_data_reference = TrafficStore.getAppGroupsType()
      .map(type => scope[type].href.split('/').pop())
      .sort((a, b) => a - b)
      .join(' | ');

    return ruleset;
  },

  createRingFenceRuleset(scope) {
    return {
      enabled: true,
      name: this.getName(scope),
      description: intl('PolicyGenerator.AutoDescription'),
      scopes: RulesetUtils.getScopesFromLabelKeys([scope]),
      rules: PolicyGeneratorUtils.getStrippedRules(this.state.ringFenceRule),
      external_data_set: 'illumio_policy_generator',
      external_data_reference: TrafficStore.getAppGroupsType()
        .map(type => scope[type].href.split('/').pop())
        .join(' | '),
    };
  },

  handleBack() {
    this.transitionTo('ringFenceChoose', {}, {appgroups: false});
  },

  render() {
    const {ringFenceRule, ringFenceScopes, providerConsumerOrder, trafficSpinner, ruleSpinner, saveSpinner} =
      this.state;

    const rows = ringFenceScopes ? ringFenceScopes.map(scope => ({scope, ...ringFenceRule[0]})) : [];

    if (trafficSpinner || ruleSpinner) {
      return (
        <div className="RBConfigure RBConfigure--Extra">
          <Banner
            type="progresscentered"
            header={intl('PolicyGenerator.CalculationInProgress')}
            message={intl('PolicyGenerator.Spinner.LocatingAppGroups')}
          />
        </div>
      );
    }

    const ruleColumns = [
      {
        key: 'scope',
        label: intl('Common.Rulesets'),
        style: 'ringfence-ruleset',
        format: value => {
          const appGroupName = `: ${RenderUtils.truncateAppGroupName(this.getName(value), 40, [20, 10, 10])}`;

          return (
            <div>
              <div className="PolicyGeneratorRingfence-ruleset">{intl('Common.Ruleset') + appGroupName}</div>
              <div className="PolicyGeneratorRingfence-scope">{intl('Common.Scope') + appGroupName}</div>
            </div>
          );
        },
      },
      ...ProviderConsumerUtils.setProviderConsumerColumnOrderArrow(
        {
          key: 'providers',
          label: intl('Common.Destinations'),
          style: 'providers',
          format: (value, row) => GridDataUtils.formatRuleEntities(value, row),
        },
        {
          key: 'services',
          label: __ANTMAN__ ? intl('Common.Service') : intl('Rulesets.Rules.ProvidingService'),
          style: 'service',
          format: value => value && value[0].name,
        },
        {
          key: 'consumer_to_provider_arrow',
          style: 'consumerToProviderArrow',
        },
        {
          key: 'consumers',
          label: intl('Common.Sources'),
          style: 'all-entities',
          format: (value, row) => GridDataUtils.formatRuleEntities(value, row),
        },
        providerConsumerOrder,
      ),
    ];

    return (
      <div className="RBPreview RBPreview--RingFence">
        {saveSpinner ? (
          <Banner
            type="progresscentered"
            header={intl('PolicyGenerator.CalculationInProgress')}
            message={intl('PolicyGenerator.Spinner.CreatingRules')}
          />
        ) : null}
        <div className="RBPreview-Content">
          <ProgressBar
            steps={[intl('PolicyGenerator.RingFence.ChooseAppGroups'), intl('PolicyGenerator.PreviewRules')]}
            active={1}
          />
          <div className="RBConfigure-Content-Nav">
            <Button text={intl('Common.Back')} type="secondary" onClick={this.handleBack} tid="back" />
          </div>
          <div className="RBPreview-Content-ScopesRules">
            <Grid columns={ruleColumns} data={rows} />
          </div>
          <div className="RBPreview-Content-Confirm">
            <h3 className="RBPreview-Content-Confirm-Title">{intl('PolicyGenerator.RingFence.CreatingRulesets')}</h3>
            <div className="RBPreview-Content-Confirm-Info">
              <br />
              {ringFenceScopes &&
                ringFenceScopes.length > 0 &&
                intl('PolicyGenerator.RingFence.NewRulesets', {count: ringFenceScopes.length}, {html: true})}
              <br />
            </div>
            <div className="RBPreview-Content-Confirm-Info">
              {intl('PolicyGenerator.RingFence.Contents')}
              <br />
            </div>
            <div className="RBPreview-Content-Confirm-Buttons">
              <Button text={intl('Common.Cancel')} icon="cancel" type="secondary" onClick={this.handleCancel} />
              <Button text={intl('Common.Save')} icon="save" onClick={this.handleSave} />
            </div>
          </div>
        </div>
      </div>
    );
  },
});
